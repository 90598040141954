import React from 'react';
import PropTypes from 'prop-types';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faLongArrowAltRight,
  faClock,
  faInfo,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faCalendarCheck,
  faMobileAlt,
  faHome,
  faSubway,
  faCar,
  faAppleAlt,
  faCalendarAlt,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';

import {
  faInstagram,
  faFacebookSquare,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import { faClose, faPlus, faMinus } from './custom-icons';

library.add(
  faEnvelope,
  faInstagram,
  faFacebookSquare,
  faLinkedin,
  faYoutube,
  faPhone,
  faMapMarkerAlt,
  faLongArrowAltRight,
  faClock,
  faInfo,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faCalendarCheck,
  faMobileAlt,
  faHome,
  faSubway,
  faCar,
  faAppleAlt,
  faCalendarAlt,
  faUtensils,
  faClose,
  faPlus,
  faMinus,
);

const AwesomeIcon = ({ ...props }) => {
  return <FontAwesomeIcon {...props} icon={[props.prefix, props.icon]} />;
};

export default AwesomeIcon;

AwesomeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};

AwesomeIcon.defaultProps = {
  prefix: 'fas',
};
