import React, { useState } from 'react';
import { useTrail, a, useTransition } from 'react-spring';

import AwesomeIcon from 'ui/atoms/AwesomeIcon';

import { staticData } from 'config/staticData';

import Chat from 'assets/icons/chat.png';

const FlyingButton = () => {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  const transitions = useTransition(open, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: open,
    delay: 200,
    config: { mass: 5, tension: 2000, friction: 200 },
    // onRest: () => setToggle(!toggle),
  });

  return (
    <>
      <LittleButtons open={open}>
        <a href={staticData.tel.href}>
          <div className="shadow-small icon row justify-center flex-align-center">
            <AwesomeIcon icon="phone" />
          </div>
        </a>
        <a href={staticData.email.href}>
          <div className="shadow-small icon row justify-center flex-align-center">
            <AwesomeIcon icon="envelope" />
          </div>
        </a>
      </LittleButtons>
      <div
        onClick={() => setOpen(false)}
        className="row justify-center flex-align-center flying-button shadow-large pulse"
      >
        {transitions((styles, item) =>
          item ? (
            <a.a
              href="mailto:office@tomas-hope.at"
              style={{ ...styles, top: 0, left: 0 }}
              className="row flex-align-center justify-center fw fh"
            >
              <AwesomeIcon icon="times" />
            </a.a>
          ) : (
            <a.a
              href="mailto:office@tomas-hope.at"
              style={{ ...styles, top: 0, left: 0 }}
              className="row flex-align-center justify-center fw fh"
            >
              <img style={{ width: '40px' }} alt="" src={Chat.src} />
            </a.a>
          ),
        )}
      </div>
    </>
  );
};

const LittleButtons = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    y: open ? 0 : 10,
    height: open ? 80 : 0,
    from: { opacity: 0, y: 10, height: 80 },
  });

  return (
    <div className="flying-button-container row justify-center">
      {trail.map(({ height, ...style }, i) => (
        <a.div key={i} style={style}>
          <a.div style={{ height }}>{items[i]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

export default FlyingButton;
