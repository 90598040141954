import React from 'react';
import { CookieContextProvider, useCookiePopup } from './cookie-context';
import { ThemeContextProvider, useTheme } from './theme-context';

const Context = ({ children }) => {
  return <CookieContextProvider>{children}</CookieContextProvider>;
};

export default Context;
export { useTheme, useCookiePopup };
