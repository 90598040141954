import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSetLoaded } from './LoaderContext';

export * from './LoaderContext';
export * from './LoaderTransition';

export const Loader = ({ loaded }) => {
  const [animating, setAnimating] = useState();
  const setLoaded = useSetLoaded();
  const history = useHistory();
  const [iLoaded, setILoaded] = useState();
  const [lastLocation, setLastLocation] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== lastLocation) return;
    if (animating) return;
    if (iLoaded === loaded) return;
    setAnimating(true);
    setILoaded(loaded);
    setTimeout(() => {
      setAnimating(false);
    }, 700);
  }, [loaded, animating, iLoaded, pathname, lastLocation]);

  useEffect(() => {
    if (pathname !== lastLocation) {
      setLastLocation(pathname);
      if (history.action === 'POP') return;
      setLoaded(false);
    }
  }, [pathname, lastLocation, setLoaded, history]);

  return (
    <div
      className={`loader__container column flex-align-center justify-center ${
        iLoaded ? 'loaded' : ''
      } `}
    >
      <div className="loader-anim"></div>
      <img
        className="l-logo mb-3"
        style={{ maxWidth: '250px' }}
        src="/logo.png"
        alt="logo"
      />
      <div className="loader"></div>
      {/* <div className="loader l"></div>
      <div className="loader r"></div> */}
    </div>
  );
};
