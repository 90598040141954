import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WhiteHeart from 'assets/images/deco/heart-white.svg';

const NavElement = ({ text, link, subMenu, ...props }) => {
  const location = useLocation();

  console.log(location);

  if (text.search('http') !== -1) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="row l-spacing-1 mb-2 fw-500 white uppercase flex-align-center nav-link nav-hover"
        href={location.pathname === '/' ? link : `/${link}`}
      >
        <span className="fw">{text}</span>
      </a>
    );
  } else {
    return (
      <a
        {...props}
        exact
        className="row l-spacing-1 mb-2 fw-500 white uppercase flex-align-center nav-link nav-hover"
        href={location.pathname === '/' ? link : `/${link}`}
      >
        <img alt="" src={WhiteHeart} />
        <span className="fw">{text}</span>
      </a>
    );
  }
};

export default NavElement;
