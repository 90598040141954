import { useEffect, useState } from 'react';

export const checkMobile = () =>
  'ontouchstart' in document.documentElement ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    checkMobile && window.innerWidth <= 768,
  );
  useEffect(() => {
    if (!checkMobile()) return;
    if (window.innerWidth <= 768) setIsMobile(true);
    const handleResize = () => {
      if (!checkMobile()) return;
      if (window.innerWidth <= 768) setIsMobile(true);
      else setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log(isMobile);
  return isMobile;
};
