import React, { useState } from 'react';
import { useCookiePopup } from './CookieContext';
import map from 'lodash/map';

import CheckBox from 'ui/atoms/CheckBox';
import Button from 'ui/atoms/Button';

const data = {
  // mapbox: {
  //   lable: 'Mapbox',
  //   desc: 'Interaktive Karte',
  // },
  googleAnalytics: {
    lable: 'Google Analytics',
    desc: 'Google LLC, USA',
  },
};

export const CookiePopup = () => {
  const { setCookiePreferences, noticed, ...cookieState } = useCookiePopup();
  const [showOptions, setShopOptions] = useState(false);

  const handleOnChange = ({ target: { name, checked } }) => {
    setCookiePreferences({
      [name]: checked,
    });
  };

  // const handleMany = (bool) => () => {
  //   let newState = cookieState;
  //   Object.keys(newState).forEach((key) => {
  //     newState[key] = bool;
  //   });

  //   setCookiePreferences({ noticed: true, ...newState });
  // };

  // const selected = useMemo(
  //   () => Object.keys(cookieState).filter((key) => cookieState[key]),
  //   [cookieState],
  // );

  if (noticed) return null;
  return (
    <div className="cookie-notice__container row justify-end flex-align-end p-2 p-x2 pr-x3">
      <div className="cookie-notice">
        {/* <div className="cookie-notice__close">
          <img src={closeIcon} alt="close icon" />
        </div> */}
        <div className="form-group align-center">
          <h3 className="fw-bold l-spacing-0">Wir verwenden Cookies</h3>
        </div>
        <div className="form-group pb-x3 gray-300 pb-2 align-center">
          <p>
            Mit der Nutzung dieser Website erklären Sie sich damit
            einverstanden, dass wir Cookies verwenden. Weitere Informationen
            dazu finden Sie in unserem Datenschutz.
          </p>
        </div>

        {showOptions ? (
          <div
            className="pb-x3 row flex-align-stretch "
            style={{ margin: '0 -8px' }}
          >
            {map(cookieState, (value, key) => (
              <div className="col-md-6">
                <div
                  key={key}
                  className="form-group checkbox-container row flex-align-center justify-space-between"
                >
                  <label>
                    <div className="h6 fw-bold">{data[key].lable}</div>
                    <p>{data[key].desc}</p>
                  </label>
                  <CheckBox
                    checked={value}
                    onChange={handleOnChange}
                    name={key}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <div className="form-group btn-container row align-center justify-space-between">
          <Button
            className="btn-outlined secondary mr-x1"
            onClick={() => setShopOptions(!showOptions)}
          >
            {showOptions ? 'Zurück' : 'Mehr Erfahren'}
          </Button>
          <Button
            className="btn-outlined align-center"
            onClick={() => setCookiePreferences({ noticed: true })}
            style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
          >
            {showOptions ? 'mit Auswahl fortfahren' : 'Zustimmen & Weiter'}
          </Button>
        </div>
      </div>
    </div>
  );
};
