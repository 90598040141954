import './scss/index.min.css';

import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// @TODO remove this
// import 'config/i18n';

import { BrowserRouter } from 'react-router-dom';
import { isIE, isSafari } from 'utils';

import { LoaderContextProvider } from 'components/Loader';
import { CookieContextProvider } from 'components/CookiePopup/CookieContext';
import ScrollRestoration from './router/scrollRestoration';
import Context from 'context';
import MainRouter from './router';

if (isIE()) {
  document.body.classList.add('is-ie');
  console.log('isie');
}

if (isSafari) {
  document.body.classList.add('is-safari');
}

const App = () => (
  <BrowserRouter>
    <ScrollRestoration>
      <LoaderContextProvider>
        <CookieContextProvider>
          <Context>
            <MainRouter />
          </Context>
        </CookieContextProvider>
      </LoaderContextProvider>
    </ScrollRestoration>
  </BrowserRouter>
);
export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
