import { config } from 'config';
import React, { useState, createContext, useEffect, useContext } from 'react';

import * as ReactGA from 'utils/ReactGA';
import { getData, saveData } from 'utils';
import { CookiePopup } from './CookpiePopup';
import { ReactRouterGa } from './ReactRouterGa';

const initialState = {
  // mapbox: true,
  googleAnalytics: false,
  noticed: false,
};
const COOKIE_NOTICE_LS = 'cookie-notice';

const CookieContext = createContext();
export const useCookiePopup = () => useContext(CookieContext);

export const CookieContextProvider = ({ children, includePopup = true }) => {
  const [state, setState] = useState(getData(COOKIE_NOTICE_LS) || initialState);

  // handle Google Analytics
  useEffect(() => {
    if (state.noticed) saveData(COOKIE_NOTICE_LS, state);
    // eslint-disable-next-line
  }, [state.noticed]);

  if (state.noticed && state.googleAnalytics)
    setTimeout(() => {
      ReactGA.initialize(config.googleAnalyticsId);
    }, 5000);

  return (
    <>
      <CookieContext.Provider
        value={{
          ...state,
          setCookiePreferences: (changes) => setState({ ...state, ...changes }),
        }}
      >
        <ReactRouterGa />
        {includePopup ? <CookiePopup /> : null}
        {children}
      </CookieContext.Provider>
    </>
  );
};
