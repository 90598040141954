export const staticData = {
  companyName: 'Tomas Hope Foundation e.V',
  email: {
    href: 'mailto:office@tomas-hope.at',
    text: 'office@tomas-hope.at',
    target: '__blank',
  },
  tel: {
    href: 'tel:+436641030148',
    text: '+43 664 10 30 148',
  },
  address: ['Neuer Platz 7/2', '9020 Klagenfurt am Wörthersee'],
  website: {
    href: 'https://tomas-hope.at',
    text: 'tomas-hope.at',
  },
  facebookLink: 'https://www.facebook.com/',
  instagramLink: 'https://www.instagram.com/',
  youtubeLink: 'https://www.youtube.com/',
  linkedinLink: 'https://www.linkedin.com/',
};
