import { useEffect } from 'react';
import { to, useSpring, a } from 'react-spring';
import { isIE } from 'utils';

export const useMouseMove = (config = {}) => {
  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    config,
  }));

  useEffect(() => {
    if (isIE()) return;
    const onMove = ({ clientX: x, clientY: y }) => {
      set({ xy: [x / window.innerWidth - 0.5, y / window.innerHeight - 0.5] });
    };

    window.addEventListener('mousemove', onMove);
    return () => {
      window.removeEventListener('mousemove', onMove);
    };
    // eslint-disable-next-line
  }, []);

  return [xy, to];
};

export const MouseParallax = ({
  xy,
  speed = 1,
  children,
  style = {},
  ...props
}) => {
  const transform = (x, y) => `translate3d(${x * speed}px,${y * speed}px,0)`;
  if (!xy) return children;
  return (
    <a.div {...props} style={{ ...style, transform: xy.to(transform) }}>
      {children}
    </a.div>
  );
};
