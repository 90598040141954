import Home from 'assets/images/header/home.jpg';

export const mainLinks = [
  {
    link: '#home',
    text: 'Home',
    img: Home,
  },
  {
    link: '#hope-foundation',
    text: 'Hope Foundation',
    img: Home,
  },
  {
    link: '#wunschformular',
    text: 'Wunschformular',
    img: Home,
  },
  {
    link: '#projekte',
    text: 'Aktuelles',
    img: Home,
  },
  {
    link: '#spenden',
    text: 'Spende',
    img: Home,
  },
  // {
  //   link: '#supporter',
  //   text: 'Unterstützer',
  //   img: Home,
  // },
  {
    link: '#faq2',
    text: 'Faq',
    img: Home,
  },
  {
    link: '#kontakt',
    text: 'Kontakt',
    img: Home,
  },
];

export const legalLinks = [
  { link: '/impressum', text: 'Impressum' },
  { link: '/datenschutz', text: 'Datenschutzerklärung' },
  // { link: '/agb', text: 'AGB' },
];
