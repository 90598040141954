import React from 'react';

import LittleHeart from 'assets/images/little-heart.png';

const NavButton = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={`nav-button ${className} row justify-center flex-align-center`}
    >
      <img className="little-heart" alt="" src={LittleHeart.src} />
      <div className="column flex-align-end">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default NavButton;
