import React from 'react';
import { staticData, legalLinks } from 'config';

import AwesomeIcon from 'ui/atoms/AwesomeIcon';
import Media from 'ui/atoms/Media';

import { DelayLinkTransition } from '../DelayLink';

import MakeAWish from 'assets/images/deco/footer-make-a-wish.png';
import TomasHopeFoundation from 'assets/logo-white.png';

export const Footer = () => {
  const { address, email, tel, companyName } = staticData;
  return (
    <>
      <footer className="footer col pt-0 pl-2 pl-md-5 pr-2 pr-md-5 pb-5 pb-md-10">
        <div className="row container white justify-center pt-10 pb-5 pb-md-1">
          <div className="footer-logo">
            <Media src={MakeAWish.src} srcSet={MakeAWish.srcSet} />
          </div>
        </div>

        <div className="container-big fw row nop justify-space-between white">
          <div className="col-md-6 nopt nopr nopb row flex-align-end pl-0 pl-ms-0 pl-ml-5 pl-xl-0">
            <p className="mb-2 mb-ms-0 fw">Kontakt: Michaela Thalhammer</p>
            <p className="mb-2 mb-ms-0 fw">
              <a className="simple-hover" href={tel.href}>
                {tel.text}
              </a>{' '}
              <span> / </span>{' '}
              <a className="simple-hover" href={email.href}>
                {email.text}
              </a>
            </p>
            <p className="mb-2 mb-ms-0 fw">
              © {companyName}
              {legalLinks.map(({ link, text, normalLink }) =>
                !normalLink ? (
                  <>
                    {' '}
                    <span className="vertical-trenner">|</span>{' '}
                    <DelayLinkTransition key={link} to={link}>
                      <span className="simple-hover">{text}</span>
                    </DelayLinkTransition>
                  </>
                ) : (
                  <a
                    className="simple-hover"
                    key={text}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link}
                  >
                    {text}
                  </a>
                ),
              )}
            </p>
          </div>
          <div className="col-md-6 nopl nopr nopb pt-5 pt-sm-0 row justify-md-end">
            <Media
              style={{ maxWidth: '240px' }}
              src={TomasHopeFoundation.src}
              srcSet={TomasHopeFoundation.srcSet}
            />
          </div>
        </div>
      </footer>
    </>
  );
};

// © COPYRIGHT {new Date().getFullYear()} - {companyName}
